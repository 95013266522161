<template>
    <el-form ref="clientFactoringInfo" class="card-tail w-full half-width" :rules="rules" label-position="top" :model="clientFactoringInfo" v-loading="$waiting.is('loading')">
        <div class="p-2" :class="{ inactive: !isActive }">
            <div style="display:flex; width:100%; justify-content: space-between;">
                <div>
                    <label style="font-weight: 700; font-size: 15px;">Verksamhetsbeskrivning</label>
                    <p style="width: 40%; font-weight: 400; font-size: 14px; line-height: 1.4; margin-bottom: 15px;">
                        {{ clientFactoringInfo.description }}
                    </p>
                </div>
            </div>
            <div>
                <div style="width: 30%; font-weight: 700; font-size: 14px; margin-bottom: 10px; display: flex;">
                    <label style="margin-right: 8px;">Riskklass:</label>
                    {{ clientFactoringInfo.riskValueKYC }}
                </div>
                <div style="width: 100%; font-weight: 700; font-size: 14px; display: column; margin-bottom:30px;">
                    <el-row>
                        <el-col :span="21">Särskilda villkor signade: </el-col>
                    </el-row>
                    <div v-if="clientFactoringInfo.signedByClientDate !== null">
                        <div style="font-weight: 500; font-size:14px; margin-bottom: 5px;">{{ clientFactoringInfo.signedByClientDate }}</div>
                        <div v-for="(name, index) in clientFactoringInfo.signatoriesJsonAfterSign" :key="index" style="width: 50%; font-weight: 500; font-size: 14px;">{{ name }} <br /></div>
                    </div>
                </div>
            </div>

            <div style="overflow: hidden;">
                <div style="float: left; width: 100%; font-size: 14px; margin-bottom: 20px" :class="{ inactive: !isActive }">
                    <el-row>
                        <el-col v-if="clientFactoringInfo.activeOpenBankingHasChanged" :span="17" style="font-weight:700;">Aktiv Open-banking</el-col>
                        <el-col v-else :span="21" style="font-weight:700;">Aktiv Open-banking</el-col>
                        <el-col :span="1">
                            <div v-if="clientFactoringInfo.activeOpenBanking && isRenderedData">
                                <i v-if="!switchStates.switchStatesOpenBanking" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesOpenBanking" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                            </div>
                            <div v-else-if="!clientFactoringInfo.activeOpenBanking && isRenderedData">
                                <i v-if="!switchStates.switchStatesOpenBanking" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesOpenBanking" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                            </div>
                        </el-col>
                        <el-col :span="2" v-if="isRenderedData">
                            <el-switch
                                v-model="switchStates.switchStatesOpenBanking"
                                :disabled="switchStates.switchStatesOpenBanking"
                                :class="{ 'switch-on': switchStates.switchStatesOpenBanking, 'switch-off': !switchStates.switchStatesOpenBanking }"
                                active-color="#13ce66"
                                inactive-color="#919191"
                                @change="showModalAdd('switchStatesOpenBanking', $event)"
                            />
                        </el-col>
                        <el-col :span="3" v-if="clientFactoringInfo.activeOpenBankingHasChanged" align="right" style="height:20px; margin-left:10px;">
                            <el-button :loading="loadingactiveOB" type="success" @click="renderNewInfo(decisionTypes.activeOpenBanking)">Uppdatera</el-button>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :offset="1" v-if="!newClientInfo.activeOBChanged">
                            <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesOpenBanking') && switchStates.switchStatesOpenBanking">
                                    Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row style="margin-top:15px;">
                        <el-col v-if="clientFactoringInfo.showDebtHasChanged" :span="17" style="font-weight: 700">Skuldfri Skattekonto</el-col>
                        <el-col v-else :span="21" style="font-weight: 700">Skuldfri Skattekonto</el-col>
                        <el-col :span="1">
                            <div v-if="clientFactoringInfo.hasNoDebtSkattekonto && isRenderedData">
                                <i v-if="!switchStates.switchStatesSkuldfri" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesSkuldfri" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                            </div>
                            <div v-else-if="!clientFactoringInfo.hasNoDebtSkattekonto && isRenderedData">
                                <i v-if="!switchStates.switchStatesSkuldfri" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesSkuldfri" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                            </div>
                        </el-col>
                        <el-col :span="2" v-if="isRenderedData">
                            <el-switch
                                v-model="switchStates.switchStatesSkuldfri"
                                :disabled="switchStates.switchStatesSkuldfri"
                                :class="{ 'switch-on': switchStates.switchStatesSkuldfri, 'switch-off': !switchStates.switchStatesSkuldfri }"
                                active-color="#13ce66"
                                inactive-color="#919191"
                                @change="showModalAdd('switchStatesSkuldfri', $event)"
                            />
                        </el-col>
                        <el-col :span="3" v-if="clientFactoringInfo.showDebtHasChanged" align="right" style="height:20px; margin-left:10px;">
                            <el-button :loading="loadingNoDebt" type="success" @click="renderNewInfo(decisionTypes.debtTaxAccount)">Uppdatera</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" :offset="1" class="custom-font-style">Skuldsaldo: {{ clientFactoringInfo.showDebt | swedishNumberFormat }} kr</el-col>
                    </el-row>

                    <el-row>
                        <el-col :offset="1" v-if="!newClientInfo.noDebtChanged">
                            <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesSkuldfri') && switchStates.switchStatesSkuldfri">
                                    Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row style="margin-top:15px;">
                        <el-col v-if="clientFactoringInfo.showResultHasChanged" :span="17" style="font-weight: 700">Positivt res 6 mån</el-col>
                        <el-col v-else :span="21" style="font-weight: 700">Positivt res 6 mån</el-col>

                        <el-col :span="1">
                            <div v-if="clientFactoringInfo.positiveRes6Months && isRenderedData">
                                <i v-if="!switchStates.switchStatesPositivtRes" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesPositivtRes" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                            </div>
                            <div v-else-if="!clientFactoringInfo.positiveRes6Months && isRenderedData">
                                <i v-if="!switchStates.switchStatesPositivtRes" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesPositivtRes" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                            </div>
                        </el-col>

                        <el-col :span="2" v-if="isRenderedData">
                            <el-switch
                                v-model="switchStates.switchStatesPositivtRes"
                                :disabled="switchStates.switchStatesPositivtRes"
                                :class="{ 'switch-on': switchStates.switchStatesPositivtRes, 'switch-off': !switchStates.switchStatesPositivtRes }"
                                active-color="#13ce66"
                                inactive-color="#919191"
                                @change="showModalAdd('switchStatesPositivtRes', $event)"
                            />
                        </el-col>
                        <el-col :span="3" v-if="clientFactoringInfo.showResultHasChanged" align="right" style="height:20px; margin-left:10px;">
                            <el-button size="small" :loading="loadingPositiveRes" type="success" @click="renderNewInfo(decisionTypes.positiveResult6Months)">Uppdatera</el-button>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="8" :offset="1" class="custom-font-style">Resultat: {{ clientFactoringInfo.showResult | swedishNumberFormat }} kr</el-col>
                    </el-row>
                    <el-row v-if="!newClientInfo.positiveResChanged">
                        <el-col :offset="1">
                            <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesPositivtRes') && switchStates.switchStatesPositivtRes">
                                    Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row style="margin-top:15px;">
                        <el-col v-if="clientFactoringInfo.showKassaLikviditetHasChanged" :span="17" style="font-weight: 700">Kassalikviditet 1,0</el-col>
                        <el-col v-else :span="21" style="font-weight: 700">Kassalikviditet 1,0</el-col>

                        <el-col :span="1">
                            <div v-if="clientFactoringInfo.kassalikviditet1Point0 && isRenderedData">
                                <i v-if="!switchStates.switchStatesKassaLik" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesKassaLik" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                            </div>
                            <div v-else-if="!clientFactoringInfo.kassalikviditet1Point0 && isRenderedData">
                                <i v-if="!switchStates.switchStatesKassaLik" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesKassaLik" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                            </div>
                        </el-col>
                        <el-col :span="2" v-if="isRenderedData">
                            <el-switch
                                v-model="switchStates.switchStatesKassaLik"
                                :disabled="switchStates.switchStatesKassaLik"
                                :class="{ 'switch-on': switchStates.switchStatesKassaLik, 'switch-off': !switchStates.switchStatesKassaLik }"
                                active-color="#13ce66"
                                inactive-color="#919191"
                                @change="showModalAdd('switchStatesKassaLik', $event)"
                            />
                        </el-col>
                        <el-col :span="3" v-if="clientFactoringInfo.showKassaLikviditetHasChanged" align="right" style="height:20px; margin-left:10px;">
                            <el-button :loading="loadingKassalik" type="success" @click="renderNewInfo(decisionTypes.kassaLikviditet)">Uppdatera</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="custom-font-style" :span="10" :offset="1">Kassalikviditet: {{ clientFactoringInfo.showKassaLikviditet }} </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="custom-font-style" :span="10" :offset="1">Balans 1930: {{ clientFactoringInfo.balanceOn1930 | swedishNumberFormat }} kr</el-col>
                    </el-row>
                    <el-row>
                        <el-col class="custom-font-style" :span="10" :offset="1">Bokförd balans OB: {{ clientFactoringInfo.bookedBalanceValue | swedishNumberFormat }} kr</el-col>
                    </el-row>
                    <el-row>
                        <el-col class="custom-font-style" :span="10" :offset="1">
                            Tillgångar/pågående arbeten: {{ clientFactoringInfo.tillgångarVaruLagerPågåendeArbeten | swedishNumberFormat }} kr</el-col
                        >
                    </el-row>
                    <el-row>
                        <el-col class="custom-font-style" :span="10" :offset="1">Kortfristiga skulder: {{ clientFactoringInfo.kortfristigaSkulder | swedishNumberFormat }} kr</el-col>
                    </el-row>

                    <el-row>
                        <el-col :offset="1" v-if="!newClientInfo.kassalikviditetChanged">
                            <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesKassaLik') && switchStates.switchStatesKassaLik">
                                    Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <el-card class="box-card" :class="{ inactive: !isActive }">
                    <div slot="header" class="clearfix">
                        <span>Information från Syna</span>
                        <el-button :loading="loadingFetch" type="primary" style="float: right; padding: 6px 6px" @click="getClientInfo">Hämta</el-button>
                        <div v-if="clientFactoringInfo.creditReportFetchingTime != null" style="float: right; margin-right:10px; font-size:13px">
                            Senast hämtat: {{ clientFactoringInfo.creditReportFetchingTime }}
                        </div>
                        <div class="tooltip-container">
                            <div div v-if="clientFactoringInfo.hasBeenMoreThan5Days" class="tooltip-trigger">
                                <i class="fa-regular fa-circle-exclamation" style="color: #f90606;" />
                            </div>
                            <div v-if="clientFactoringInfo.hasBeenMoreThan5Days" class="tooltip-text">Dags att hämta ny information!</div>
                        </div>
                    </div>

                    <div v-if="clientFactoringInfo.creditReportFetchingTime != null" style="float: left; width: 100%; font-size: 14px; margin-bottom: 30px;">
                        <el-row>
                            <el-col :span="21" style="font-weight: 700">F-skatt</el-col>
                            <el-col :span="1">
                                <div v-if="clientFactoringInfo.hasFSkatt && isRenderedData">
                                    <i v-if="!switchStates.switchStatesFSkatt" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesFSkatt" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="!clientFactoringInfo.hasFSkatt && isRenderedData">
                                    <i v-if="!switchStates.switchStatesFSkatt" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesFSkatt" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-switch
                                    v-model="switchStates.switchStatesFSkatt"
                                    :disabled="switchStates.switchStatesFSkatt"
                                    :class="{ 'switch-on': switchStates.switchStatesFSkatt, 'switch-off': !switchStates.switchStatesFSkatt }"
                                    active-color="#13ce66"
                                    inactive-color="#919191"
                                    @change="showModalAdd('switchStatesFSkatt', $event)"
                                />
                            </el-col>
                        </el-row>

                        <el-row v-if="!removeComments">
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesFSkatt') && switchStates.switchStatesFSkatt">
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="21" style="font-weight: 700">Kreditkontroll</el-col>
                            <el-col :span="1">
                                <div v-if="clientFactoringInfo.creditCheck && isRenderedData">
                                    <i v-if="!switchStates.switchStatesKreditkontroll" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesKreditkontroll" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="!clientFactoringInfo.creditCheck && isRenderedData">
                                    <i v-if="!switchStates.switchStatesKreditkontroll" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesKreditkontroll" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesKreditkontroll"
                                        :disabled="switchStates.switchStatesKreditkontroll"
                                        :class="{ 'switch-on': switchStates.switchStatesKreditkontroll, 'switch-off': !switchStates.switchStatesKreditkontroll }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesKreditkontroll', $event)"
                                    />
                                </el-col>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="7" :offset="1" class="custom-font-style">Betalningsanmärkningar: {{ clientFactoringInfo.showPaymentNoteCount }} st</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="7" :offset="1" class="custom-font-style">Total skuld: {{ clientFactoringInfo.showPaymentNoteTotalDebt | swedishNumberFormat }} kr</el-col>
                        </el-row>

                        <el-row v-if="!removeComments">
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesKreditkontroll') && switchStates.switchStatesKreditkontroll">
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>

                <div style="float: left; width: 100%; font-size: 14px;" :class="{ inactive: !isActive }">
                    <el-row>
                        <el-col :span="17" style="font-weight:700">Fakturainformation</el-col>
                        <el-col :span="2" />
                    </el-row>
                    <el-row>
                        <el-col :span="21">Snitt senaste 10 fakturorna</el-col>
                        <el-col :span="3">{{ clientFactoringInfo.averageLast10Invoices | swedishNumberFormat }} kr</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="21">Genomsnittlig betaltid</el-col>
                        <el-col :span="3">{{ clientFactoringInfo.onAveragePaytime }} dagar</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="21">Antal kunder i tjänsten</el-col>
                        <el-col :span="3">{{ clientFactoringInfo.numberOfCustomersInService }} st</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="21">Antal fakturor senaste 12 månaderna</el-col>
                        <el-col :span="3">{{ clientFactoringInfo.numberOfInvoicesLast12Months }} st</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="21">Klient sedan</el-col>
                        <el-col :span="3">{{ formatDate(clientFactoringInfo.clientSince) }}</el-col>
                    </el-row>

                    <el-row style="margin-top:15px;">
                        <el-col v-if="clientFactoringInfo.showNumberOfUnpaidInvoicesHasChanged" :span="17" style="font-weight: 700">Färre än 3 förfallna fakturor</el-col>
                        <el-col v-else :span="21" style="font-weight: 700">Färre än 3 förfallna fakturor</el-col>

                        <el-col :span="1">
                            <div v-if="clientFactoringInfo.lessThan3UnpaidInv && isRenderedData">
                                <i v-if="!switchStates.switchStatesLessThan3" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesLessThan3" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                            </div>
                            <div v-else-if="!clientFactoringInfo.lessThan3UnpaidInv && isRenderedData">
                                <i v-if="!switchStates.switchStatesLessThan3" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                <i v-if="switchStates.switchStatesLessThan3" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                            </div>
                        </el-col>
                        <el-col :span="2" v-if="isRenderedData">
                            <el-switch
                                :disabled="switchStates.switchStatesLessThan3"
                                v-model="switchStates.switchStatesLessThan3"
                                active-color="#13ce66"
                                inactive-color="#919191"
                                @change="showModalAdd('switchStatesLessThan3', $event)"
                            />
                        </el-col>
                        <el-col :span="3" v-if="clientFactoringInfo.showNumberOfUnpaidInvoicesHasChanged" align="right" style="height:20px; margin-left:10px;">
                            <el-button :loading="loadingLessThan" type="success" @click="renderNewInfo(decisionTypes.lessThan3Invoices)">Uppdatera</el-button>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="8" :offset="1" class="custom-font-style">Fakturor: {{ clientFactoringInfo.showNumberOfUnpaidInvoices }} st</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" :offset="1" class="custom-font-style">Total: {{ clientFactoringInfo.totalAmountInvoicesUnpaid | swedishNumberFormat }} kr</el-col>
                    </el-row>
                    <el-row>
                        <el-col :offset="1" v-if="!newClientInfo.lessThan3Changed">
                            <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesLessThan3') && switchStates.switchStatesLessThan3">
                                    Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div style="width: 100%; margin-top:15px; margin-bottom: 30px; font-size: 14px;">
                <el-row v-if="clientFactoringInfo.activeOpenBanking">
                    <el-col :span="21" style="font-weight:700">Likviditetströskel</el-col>
                    <el-col :span="3" style="font-weight:700">{{ clientFactoringInfo.showLiquidityTreshold | swedishNumberFormat }} kr</el-col>
                    <el-col :span="7" :offset="1">Tidpunkt: {{ formatDate(clientFactoringInfo.liquidityThresholdPointOneDate) }}</el-col>
                    <el-col :span="13">Saldo: {{ clientFactoringInfo.liquidityThresholdPointOneBalance | swedishNumberFormat }} kr</el-col>
                    <el-col :span="7" :offset="1">Tidpunkt: {{ formatDate(clientFactoringInfo.liquidityThresholdPointTwoDate) }}</el-col>
                    <el-col :span="13">Saldo: {{ clientFactoringInfo.liquidityThresholdPointTwoBalance | swedishNumberFormat }} kr</el-col>
                    <el-col :span="7" :offset="1">Tidpunkt: {{ formatDate(clientFactoringInfo.liquidityThresholdPointThreeDate) }}</el-col>
                    <el-col :span="13">Saldo: {{ clientFactoringInfo.liquidityThresholdPointThreeBalance | swedishNumberFormat }} kr</el-col>
                </el-row>
                <el-row style="margin-top: 15px">
                    <el-col :span="21" style="font-weight:700">Omsättning 6 månader</el-col>
                    <el-col :span="3" style="font-weight:700">{{ clientFactoringInfo.showRevenue | swedishNumberFormat }} kr</el-col>
                </el-row>
                <el-row style="margin-top:15px;">
                    <el-col :span="21" style="font-weight:700">Föreslagen limit</el-col>
                    <el-col :span="3" style="font-weight:700">{{ clientFactoringInfo.suggestedLimit | swedishNumberFormat }} kr</el-col>
                </el-row>

                <el-row style="margin-top:10px;">
                    <div style="display: flex; align-items: center" :class="{ inactive: !isActive }">
                        <el-col :span="18" style="font-weight:700">Beviljad limit</el-col>
                        <el-col :span="2">
                            <el-button v-if="clientFactoringInfo.signee2 === null" :class="{ inactive: !isActive }" type="primary" @click="showModalAdd('approvedLimit', $event)">OK</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item class="w-full" prop="approvedLimit" style="margin-bottom: 0px;">
                                <InputCurrency v-model="approvedLimit" :custom-class="inputBorder" />
                            </el-form-item>
                            <!-- <el-input

                                style="border-radius: 3px; box-shadow: 2px 2px 2px black; border: 1px solid black; margin-left: 10px;"
                                placeholder="0"
                                :disabled="false"
                                v-model="approvedLimit"
                            /> -->
                        </el-col>
                    </div>
                </el-row>

                <el-row>
                    <el-col :offset="1" v-if="!newClientInfo.kassalikviditetChanged">
                        <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                            <div style="font-weight:600;" v-if="decisionType == selectedDecisionType('approvedLimit')">Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <ModalAdd
                @update-displayed-comment="handleUpdateDisplayedComment"
                :factoring-decision-id="factoringDecisionId"
                :visible="this.modalVisible"
                :decision-type-identifier="selectedDecisionType(currentSwitchKey)"
                :decision-data="{
                    activeOpenBanking: clientFactoringInfo.activeOpenBanking,
                    hasNoDebtSkattekonto: clientFactoringInfo.hasNoDebtSkattekonto,
                    showDebt: clientFactoringInfo.showDebt,
                    positiveRes6Months: clientFactoringInfo.positiveRes6Months,
                    showResult: clientFactoringInfo.showResult,
                    kassalikviditet1Point0: clientFactoringInfo.kassalikviditet1Point0,
                    showKassaLikviditet: clientFactoringInfo.showKassaLikviditet,
                    lessThan3UnpaidInv: clientFactoringInfo.lessThan3UnpaidInv,
                    showNumberOfUnpaidInvoices: clientFactoringInfo.showNumberOfUnpaidInvoices,
                    totalAmountInvoicesUnpaid: clientFactoringInfo.totalAmountInvoicesUnpaid,
                    approvedLimit: approvedLimit,
                }"
                @close="onModalAddClose"
                @clear-signees="clearSignees"
                @lagg-till-click="hideModalSwitchOn"
            />

            <RadioButtonList
                v-if="signee1 !== null && clientFactoringInfo.shouldShowYesOrNo"
                :entries="yesNo"
                v-model="contractChoice"
                :horizontal="true"
                label="Ska klienten signera ett kreditavtal i Assently eller ej?"
            />
            <ul v-if="signee1 !== null && signee2 === null && contractChoice !== 2">
                <label style="font-weight: 700; font-size: 15px; margin-bottom:15px; margin-top: 10px;">Firmatecknare</label>
                <li v-for="(signatory, index) in clientFactoringInfo.signatoriesJsonBeforeSign" :key="index">
                    <div>{{ signatory }}</div>
                </li>
            </ul>

            <div v-if="signee1 !== null && signee2 === null && contractChoice !== 2" style="margin-top: 30px; width: 100%;">
                <el-row :gutter="50">
                    <!-- Column for Names -->
                    <el-col :span="10">
                        <label for="nameSearchInput" style="font-weight: 700; font-size: 15px;">Namn</label>
                        <el-input v-model="nameSearchQuery" :placeholder="nameSearchQuery ? nameSearchQuery : 'Sök namn...'" @input="filterNames" :disabled="false" id="nameSearchInput" />
                        <ul>
                            <li v-for="nameEntry in filteredNameList" :key="nameEntry.email" @click="selectName(nameEntry)">
                                {{ nameEntry.name }}
                            </li>
                        </ul>
                    </el-col>

                    <!-- Column for Emails -->
                    <el-col :span="10">
                        <label for="emailSearchInput" style="font-weight: 700; font-size: 15px;">E-mail</label>
                        <el-input v-model="selectedEmail.email" :placeholder="selectedEmail.email ? selectedEmail.email : 'Skriv e-mail...'" id="emailSearchInput" />
                    </el-col>
                </el-row>

                <!-- Display selected name and email -->
                <div style="margin-top: 30px;" v-if="nameSearchQuery">
                    <el-button type="primary" @click="addToPersonList">Lägg till person</el-button>
                </div>

                <div v-if="selectedPersonList !== null" style="margin-top:30px;">
                    <ul>
                        <li v-for="(person, index) in selectedPersonList" :key="index">
                            {{ person.name }} - {{ person.email }}
                            <i class="fa-solid fa-trash" @click="removeFromPersonList(index)" style="cursor:pointer; color:#000000; margin-left:5px;" />
                        </li>
                    </ul>
                </div>
                <div v-else style="margin-top:30px;">
                    <ul>
                        <li v-for="(person, index) in clientFactoringInfo.factoringDecisionIndividuals" :key="index">
                            {{ person.name }} - {{ person.email }}
                            <i class="fa-solid fa-trash" @click="removeFromPersonList(index)" style="cursor:pointer; color:#000000; margin-left:5px;" />
                        </li>
                    </ul>
                </div>
            </div>

            <div style="width: 100%; margin-top:30px; margin-bottom: 30px; font-weight: 700; font-size: 14px;" :class="{ inactive: !isActive }">
                <!-- <RadioButtonList
                    v-if="signee1 !== null && clientFactoringInfo.status !== 1"
                    :entries="yesNo"
                    v-model="contractChoice"
                    :horizontal="true"
                    label="Ska klienten signera ett kreditavtal i Assently eller ej?"
                /> -->
                <div>
                    <p v-if="!creditCheckComplete" style="font-size: 13px; margin-bottom:10px; font-weight:500; color:#e31616;">
                        Gör en ny kreditkontroll på klienten innan du godkänner beslutet!
                    </p>
                    <p v-if="hasBannerIcon" style="font-size: 13px; margin-bottom:10px; font-weight: 500; color:#e31616;">
                        Godkänn särskilda villkor med kommentar innan godkännande.
                    </p>
                </div>
                <div style="display: flex; align-items: center;">
                    <el-button :loading="loadingFirst" @click="postSignee(1)" :disabled="hasBannerIcon || !creditCheckComplete">
                        Sign 1
                        <i class="fa-sharp fa-solid fa-pen" style="color: #050505; margin-left: 5px; cursor:pointer;" />
                    </el-button>
                    <div style="margin-left: 20px;">{{ signee1 }} {{ sign1Date || clientFactoringInfo.sign1Date }}</div>
                </div>
                <div style="display: flex; align-items: center;">
                    <el-button :loading="loadingSecond" @click="postSignee(2)">
                        Sign 2
                        <i class="fa-sharp fa-solid fa-pen" style="color: #050505; margin-left: 5px; cursor:pointer;" />
                    </el-button>
                    <div style="margin-left: 20px;">{{ signee2 }} {{ sign2Date || clientFactoringInfo.sign2Date }}</div>
                </div>
            </div>
            <p v-if="clientFactoringInfo.signedByClientDate === null && contractChoice === 1">Kreditavtal skickas automatiskt till klient när båda från admin godkänt</p>
            <p v-if="clientFactoringInfo.signedByClientDate === null && contractChoice === 2">Inget kreditavtal behöver signeras av klient - beslutet godkänns när båda från admin skrivit under</p>
            <p v-if="clientFactoringInfo.factoringAssentlyCaseId === null && clientFactoringInfo.status === 1">Kreditbeslut godkänt utan kreditavtal i Assently</p>
            <p v-if="clientFactoringInfo.factoringAssentlyCaseId !== null && clientFactoringInfo.status === 1">Kreditbeslut godkänt med kreditavtal i Assently</p>
            <p v-if="clientFactoringInfo.status === 2">Inväntar signering av kontrakt i Assently</p>
        </div>
    </el-form>
</template>
<script>
import Api from "../factoring.api";
import { Switch } from "element-ui";
import RadioButtonList from "../../../components/content/RadioButtonList";

const DecisionType = {
    ActiveOpenBanking: 0,
    FVat: 1,
    DebtTaxAccount: 2,
    CreditCheck: 3,
    PositiveResult6Months: 4,
    KassaLikviditet: 5,
    LessThan3Invoices: 6,
    ApprovedLimit: 7,
};

export default {
    data() {
        return {
            loadingFirst: false,
            loadingSecond: false,
            loadingactiveOB: false,
            loadingNoDebt: false,
            loadingPositiveRes: false,
            loadingKassalik: false,
            loadingLessThan: false,
            loadingFetch: false,
            signee1: "",
            signee2: "",
            sign1Date: "",
            sign2Date: "",
            commentsByDecisionType: {},
            isRenderedData: false,
            clientFactoringInfo: {},
            clientInfo: {},
            factoringComments: [],
            averageLast10Invoices: "",
            riskValueKYC: "",
            numberOfCustomersInService: "",
            description: "",
            numberOfInvoicesLast12Months: "",
            clientSince: "",
            activeOpenBanking: false,
            lessThan3UnpaidInv: false,
            sixMonthsPositiveResult: false,
            hasNoDebtSkattekonto: false,
            kassalikviditet1Point0: false,
            hasLargerDiffThan10Percent: false,
            showDebt: "",
            isDebtPopoverVisible: false,
            isDebtPopoverNotVisible: false,
            showKassaLikviditet: "",
            isLikviditetPopoverVisible: false,
            isLikviditetPopoverNotVisible: false,
            isResultPopoverVisible: false,
            isResultPopoverVisibleNegative: false,
            showResult: "",
            showRevenue: "",
            isLessThan3PopoverVisible: false,
            isLessThan3PopoverNotVisible: false,
            showNumberOfUnpaidInvoices: "",
            totalAmountInvoicesUnpaid: "",
            hasFSkatt: false,
            hasFSkattPopoverVisible: false,
            hasFSkattPopoverNotVisible: false,
            hasOBPopoverVisible: false,
            hasOBPopoverNotVisible: false,
            creditCheck: false,
            creditCheckPopoverVisible: false,
            creditCheckPopoverNotVisible: false,
            showPaymentNoteCount: "",
            showPaymentNoteTotalDebt: "",
            onAveragePaytime: "",
            showLiquidityTreshold: "",
            isSwitchOn: false,
            modalVisible: false,
            currentSwitchKey: "",
            suggestedLimit: "",
            approvedLimit: "",
            formattedApprovedLimit: "",
            factoringDecisionId: 0,
            Responsible1UserId: "",
            Responsible2UserId: "",
            isActive: false,
            status: "",
            signatoriesJsonBeforeSign: "",
            signatoriesJsonAfterSign: "",
            nameSearchQuery: "",
            emailSearchQuery: "",
            nameList: [],
            emailList: [],
            filteredNameList: [],
            selectedName: "",
            selectedEmail: { email: "" },
            selectedPersonList: [],
            individualId: 0,
            getInfoForClient: false,
            newClientInfo: {},

            decisionTypes: {
                activeOpenBanking: 0,
                fVat: 1,
                debtTaxAccount: 2,
                creditCheck: 3,
                positiveResult6Months: 4,
                kassaLikviditet: 5,
                lessThan3Invoices: 6,
                approvedLimit: 7,
            },

            switchStates: {
                switchStatesOpenBanking: false,
                switchStatesFSkatt: false,
                switchStatesSkuldfri: false,
                switchStatesKreditkontroll: false,
                switchStatesPositivtRes: false,
                switchStatesKassaLik: false,
                switchStatesLessThan3: false,
            },
            rules: {
                approvedLimit: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
            removeComments: false,
            yesNo: [
                { id: 1, value: "Ja" },
                { id: 2, value: "Nej" },
            ],
            contractChoice: undefined,
            creditCheckComplete: false,
            hasBannerIcon: false,
        };
    },
    components: {
        "el-switch": Switch,
        ModalAdd: () => import("./FactoringModalAdd.vue"),
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
        RadioButtonList,
    },

    props: {
        factoringEnabled: Boolean,
        signRequest: Object,
    },

    async created() {
        await this.loadData();
        this.$emit("factoring-enabled", this.clientFactoringInfo.factoringEnabled);

        this.searchNamesAndEmails();

        this.$parent.$on("update-client-approved", this.updateData);

        this.creditCheckComplete =
            this.clientFactoringInfo.hasBeenMoreThan5Days === false && this.clientFactoringInfo.creditReportFetchingTime !== null && this.clientFactoringInfo.creditReportFetchingTime !== undefined;
    },

    beforeDestroy() {
        this.$parent.$off("update-client-approved", this.updateData);
    },

    computed: {
        inputBorder() {
            return "w-full text-sm py-1 px-2 rounded border border-solid input-border";
        },
    },

    methods: {
        async getClientInfo() {
            this.loadingFetch = true;
            try {
                this.clientInfo = await Api.getCreditInfoForClient(this.$route.params.clientId, this.factoringDecisionId);
                console.log("🚀 ~ getClientInfo ~ clientInfo:", this.clientInfo);
            } catch (error) {
                console.error("Error fetching clients:", error);
            } finally {
                this.clientFactoringInfo.creditReportFetchingTime = this.clientInfo.creditReportFetchingTime;
                this.clientFactoringInfo.hasFSkatt = this.clientInfo.fTaxActive.active;
                this.clientFactoringInfo.creditCheck = this.clientInfo.creditCheck;
                this.clientFactoringInfo.showPaymentNoteCount = this.clientInfo.paymentNoteCount;
                this.clientFactoringInfo.showPaymentNoteTotalDebt = this.clientInfo.paymentNoteTotalDebt;
                this.clientFactoringInfo.hasBeenMoreThan5Days = false;
                this.loadingFetch = false;
                this.removeComments = true;
                this.switchStates.switchStatesFSkatt = false;
                this.switchStates.switchStatesKreditkontroll = false;
                this.signatoriesJsonBeforeSign = this.clientInfo.companySignatureList;
                this.creditCheckComplete =
                    this.clientFactoringInfo.hasBeenMoreThan5Days === false &&
                    this.clientFactoringInfo.creditReportFetchingTime !== null &&
                    this.clientFactoringInfo.creditReportFetchingTime !== undefined;

                this.$notify.success({ title: "Uppdaterat!" });
            }
        },
        async searchNamesAndEmails() {
            try {
                const response = await Api.getClientNameAndEmail(this.$route.params.clientId);
                console.log("🚀 ~ file: ClientApproved.vue:556 ~ searchNamesAndEmails ~ response:", response);
                this.nameList = response;
                this.emailList = response;
                this.individualId = response.map(person => person.individualId);
            } catch (error) {
                console.error("Error fetching clients:", error);
            }
        },
        filterNames() {
            if (!Array.isArray(this.nameList)) {
                return;
            }

            if (!Array.isArray(this.nameList) || this.nameSearchQuery.trim() === "") {
                this.filteredNameList = [];
                return;
            }
            this.filteredNameList = this.nameList.filter(name => name.name.toLowerCase().includes(this.nameSearchQuery.toLowerCase()));
        },
        selectName(nameEntry) {
            this.selectedName = nameEntry;
            this.nameSearchQuery = this.selectedName.name;
            this.selectedEmail.email = nameEntry.email;
            this.filteredNameList = [];
        },

        addToPersonList() {
            if (this.selectedName || this.nameSearchQuery) {
                console.log("🚀 ~ file: ClientApproved.vue:588 ~ addToPersonList ~ this.nameSearchQuery:", this.nameSearchQuery);
                let email = this.selectedEmail.email.trim();

                if (!this.isValidEmail(email)) {
                    alert("Invalid email format");
                    return;
                }

                const existingPerson = this.selectedName ? this.nameList.find(name => name.name === this.selectedName.name) : null;

                const individualId = existingPerson ? existingPerson.individualId : 0;

                // const newPerson = existingPerson ? existingPerson : { name: this.nameSearchQuery, email: email, individualId: individualId };
                const newPerson = existingPerson ? { ...existingPerson, email: email } : { name: this.nameSearchQuery, email: email, individualId: individualId };

                this.selectedPersonList.push(newPerson);
                console.log("🚀 ~ file: ClientApproved.vue:604 ~ addToPersonList ~ this.selectedPersonList:", this.selectedPersonList);

                this.selectedName = null;
                this.nameSearchQuery = "";
                this.selectedEmail.email = "";
            }
        },

        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        removeFromPersonList(index) {
            if (index >= 0 && index < this.selectedPersonList.length) {
                this.selectedPersonList.splice(index, 1);
            }
        },

        clearSignees() {
            this.signee1 = null;
            this.signee2 = null;
            this.sign1Date = "";
            this.sign2Date = "";
            this.clientFactoringInfo.sign1Date = "";
            this.clientFactoringInfo.sign2Date = "";
        },
        async postSignee(signee) {
            if (this.signee1 === null && signee === 2) {
                alert("Sign 1 måste signeras först");
                return;
            }

            if (this.clientFactoringInfo.hasBeenMoreThan5Days) {
                alert("Hämta ny data från Syna innan du signerar!");
                return;
            }

            const bannerIcons = document.querySelectorAll(".fa-ban");
            this.hasBannerIcon = false;

            bannerIcons.forEach(bannerIcon => {
                const iconColor = window.getComputedStyle(bannerIcon).color;

                if (iconColor === "rgb(227, 22, 22)") {
                    this.hasBannerIcon = true;
                }
            });

            if (!this.hasBannerIcon) {
                try {
                    if (signee === 1) {
                        this.loadingFirst = true;
                    } else {
                        this.loadingSecond = true;
                    }

                    const response = await Api.signDecision({
                        clientId: this.$route.params.clientId,
                        factoringDecisionId: this.factoringDecisionId,
                        nameAndEmail: this.selectedPersonList,
                        signRequestData: this.clientFactoringInfo,
                        yesOrNo: this.contractChoice === undefined ? true : false,
                    });

                    if (response.data) {
                        console.log("🚀 ~ file: ClientApproved.vue:512 ~ postSignee ~ response.data:", response.data);
                        if (signee === 1) {
                            this.signee1 = response.data.signee1;
                            this.sign1Date = response.data.sign1Date;
                        } else if (signee === 2) {
                            this.signee2 = response.data.signee2;
                            this.sign2Date = response.data.sign2Date;
                            this.isActive = false;
                        }
                    }
                    this.$notify.success({ title: "Signerat!" });
                } catch (error) {
                    console.log("Error signing decision:", error.message);
                } finally {
                    this.loadingFirst = false;
                    this.loadingSecond = false;
                }
            } else {
                alert("Samtliga villkor ej godkända. Var god lägg till kommentarer på de villkor som är röda genom att trycka på switchen.");
            }
        },

        selectedDecisionType(decisionTypeIdentifier) {
            if (decisionTypeIdentifier === "switchStatesOpenBanking") {
                return DecisionType.ActiveOpenBanking;
            } else if (decisionTypeIdentifier === "switchStatesFSkatt") {
                return DecisionType.FVat;
            } else if (decisionTypeIdentifier === "switchStatesSkuldfri") {
                return DecisionType.DebtTaxAccount;
            } else if (decisionTypeIdentifier === "switchStatesKreditkontroll") {
                return DecisionType.CreditCheck;
            } else if (decisionTypeIdentifier === "switchStatesPositivtRes") {
                return DecisionType.PositiveResult6Months;
            } else if (decisionTypeIdentifier === "switchStatesKassaLik") {
                return DecisionType.KassaLikviditet;
            } else if (decisionTypeIdentifier === "switchStatesLessThan3") {
                return DecisionType.LessThan3Invoices;
            } else if (decisionTypeIdentifier === "approvedLimit") {
                return DecisionType.ApprovedLimit;
            }
        },
        async renderNewInfo(decisionType) {
            try {
                this.newClientInfo = await Api.updateSpecificInfo({
                    clientId: this.$route.params.clientId,
                    factoringDecisionId: this.factoringDecisionId,
                    decisionType: this.getCorrectDecisionType(decisionType),
                });
            } catch (error) {
                console.log("Error rendering new info:", error.message);
            } finally {
                this.$notify.success({ title: "Ny information hämtad!" });

                if (this.newClientInfo.positiveResChanged === true) {
                    this.switchStates.switchStatesPositivtRes = false;
                    this.clientFactoringInfo.positiveRes6Months = this.newClientInfo.positiveRes6Months;
                    this.clientFactoringInfo.showResult = this.newClientInfo.showResult;
                    this.loadingPositiveRes = false;
                    this.clientFactoringInfo.showResultHasChanged = false;
                } else if (this.newClientInfo.activeOBChanged === true) {
                    this.switchStates.switchStatesOpenBanking = false;
                    this.clientFactoringInfo.activeOpenBanking = this.newClientInfo.activeOpenBanking;
                    this.loadingactiveOB = false;
                    this.clientFactoringInfo.activeOpenBankingHasChanged = false;
                } else if (this.newClientInfo.noDebtChanged === true) {
                    this.switchStates.switchStatesSkuldfri = false;
                    this.clientFactoringInfo.hasNoDebtSkattekonto = this.newClientInfo.HasNoDebtSkattekonto;
                    this.clientFactoringInfo.showDebt = this.newClientInfo.showDebt;
                    this.loadingNoDebt = false;
                    this.clientFactoringInfo.showDebtHasChanged = false;
                } else if (this.newClientInfo.kassalikviditetChanged === true) {
                    this.switchStates.switchStatesKassaLik = false;
                    this.clientFactoringInfo.kassalikviditet1Point0 = this.newClientInfo.kassalikviditet1Point0;
                    this.clientFactoringInfo.showKassaLikviditet = this.newClientInfo.showKassaLikviditet;
                    this.loadingKassalik = false;
                    this.clientFactoringInfo.showKassaLikviditetHasChanged = false;
                } else if (this.newClientInfo.lessThan3Changed === true) {
                    this.switchStates.switchStatesLessThan3 = false;
                    this.clientFactoringInfo.lessThan3UnpaidInv = this.newClientInfo.lessThan3UnpaidInv;
                    this.clientFactoringInfo.showNumberOfUnpaidInvoices = this.newClientInfo.showNumberOfUnpaidInvoices;
                    this.clientFactoringInfo.totalAmountInvoicesUnpaid = this.newClientInfo.totalAmountInvoicesUnpaid;
                    this.loadingLessThan = false;
                    this.clientFactoringInfo.showNumberOfUnpaidInvoicesHasChanged = false;
                }
            }
        },

        getCorrectDecisionType(decisionType) {
            console.log("🚀 ~ getCorrectDecisionType ~ decisionType:", decisionType);
            if (decisionType === 0) {
                this.loadingactiveOB = true;
                return DecisionType.ActiveOpenBanking;
            } else if (decisionType === 2) {
                this.loadingNoDebt = true;
                return DecisionType.DebtTaxAccount;
            } else if (decisionType === 4) {
                this.loadingPositiveRes = true;
                return DecisionType.PositiveResult6Months;
            } else if (decisionType === 5) {
                this.loadingKassalik = true;
                return DecisionType.KassaLikviditet;
            } else if (decisionType === 6) {
                this.loadingLessThan = true;
                return DecisionType.LessThan3Invoices;
            }
        },

        handleUpdateDisplayedComment(updatedComment) {
            console.log("🚀 ~ handleUpdateDisplayedComment ~ updatedComment:", updatedComment);
            const { comment, decisionType, userName } = updatedComment;

            console.log("Updated Comment:", comment);
            console.log("Decision Type:", decisionType);
            console.log("Username: ", userName);

            const decisionTypes = Object.keys(this.switchStates)[decisionType];
            this.switchStates[decisionTypes] = true;
            this.commentsByDecisionType[decisionType] = [{ userName, comment }];

            if (decisionType === 0) {
                this.newClientInfo.activeOBChanged = false;
            } else if (decisionType === 2) {
                this.newClientInfo.noDebtChanged = false;
            } else if (decisionType === 4) {
                this.newClientInfo.positiveResChanged = false;
            } else if (decisionType === 5) {
                this.newClientInfo.kassalikviditetChanged = false;
            } else if (decisionType === 6) {
                this.newClientInfo.lessThan3Changed = false;
            }
        },

        formatDate(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            return formattedDate;
        },
        hideModalSwitchOn() {
            this.modalVisible = false;
        },

        showModalAdd(switchKey, event) {
            console.log("🚀 ~ file: ClientApproved.vue:539 ~ showModalAdd ~ switchKey:", switchKey);
            if (event) {
                this.modalVisible = true;
            }
            this.currentSwitchKey = switchKey;
            this.switchStates[switchKey] = event;
        },

        onModalAddClose() {
            this.modalVisible = false;
            this.switchStates[this.currentSwitchKey] = false;
        },

        async loadData(id) {
            console.log("🚀 ~ loadData ~ id:", id);
            if (id != undefined) {
                this.factoringDecisionId = id;
                console.log("🚀 ~ file: ClientApproved.vue:618 ~ loadData ~ this.factoringDecisionId:", this.factoringDecisionId);
            }
            this.$waiting.start("loading");
            try {
                this.clientFactoringInfo = await Api.getFirstDraftOrUpdateInfo(this.$route.params.clientId, this.factoringDecisionId);

                console.log("🚀 ~ file: ClientApproved.vue:409 ~ loadData ~ this.clientFactoringInfo:", this.clientFactoringInfo);

                this.factoringDecisionId = this.clientFactoringInfo.factoringDecisionId;
                this.isRenderedData = true;
                this.averageLast10Invoices = this.clientFactoringInfo.averageLast10Invoices;
                this.riskValueKYC = this.clientFactoringInfo.riskValueKYC;

                this.numberOfCustomersInService = this.clientFactoringInfo.numberOfCustomersInService;
                this.numberOfInvoicesLast12Months = this.clientFactoringInfo.numberOfInvoicesLast12Months;
                this.description = this.clientFactoringInfo.description;
                this.clientSince = this.clientFactoringInfo.clientSince;
                this.onAveragePaytime = this.clientFactoringInfo.onAveragePaytime;

                this.activeOpenBanking = this.clientFactoringInfo.activeOpenBanking;
                this.lessThan3UnpaidInv = this.clientFactoringInfo.lessThan3UnpaidInv;
                this.sixMonthsPositiveResult = this.clientFactoringInfo.positiveRes6Months;
                this.hasNoDebtSkattekonto = this.clientFactoringInfo.hasNoDebtSkattekonto;
                this.kassalikviditet1Point0 = this.clientFactoringInfo.kassalikviditet1Point0;
                this.hasLargerDiffThan10Percent = this.clientFactoringInfo.hasLargerDiffThan10Percent;

                this.showDebt = this.clientFactoringInfo.showDebt;
                this.showKassaLikviditet = this.clientFactoringInfo.showKassaLikviditet;
                this.showResult = this.clientFactoringInfo.showResult;
                this.showRevenue = this.clientFactoringInfo.showRevenue;
                this.showNumberOfUnpaidInvoices = this.clientFactoringInfo.showNumberOfUnpaidInvoices;
                this.totalAmountInvoicesUnpaid = this.clientFactoringInfo.totalAmountInvoicesUnpaid;
                this.hasFSkatt = this.clientFactoringInfo.hasFSkatt;
                this.creditCheck = this.clientFactoringInfo.creditCheck;
                this.showPaymentNoteCount = this.clientFactoringInfo.showPaymentNoteCount;
                this.showPaymentNoteTotalDebt = this.clientFactoringInfo.showPaymentNoteTotalDebt;
                this.showLiquidityTreshold = this.clientFactoringInfo.showLiquidityTreshold;
                this.suggestedLimit = this.clientFactoringInfo.suggestedLimit;
                this.approvedLimit = this.clientFactoringInfo.approvedLimit;
                this.Responsible1UserId = this.clientFactoringInfo.Responsible1UserId;
                this.Responsible2UserId = this.clientFactoringInfo.Responsible2UserId;
                this.signee1 = this.clientFactoringInfo.signee1;
                this.signee2 = this.clientFactoringInfo.signee2;
                this.status = this.clientFactoringInfo.Status;
                this.signatoriesJson = this.clientFactoringInfo.signatoriesJson;

                if (this.signee2 !== null && this.signee1 !== null) {
                    this.isActive = false;
                } else {
                    this.isActive = true;
                }

                this.switchStates.switchStatesOpenBanking = false;
                this.switchStates.switchStatesFSkatt = false;
                this.switchStates.switchStatesSkuldfri = false;
                this.switchStates.switchStatesKreditkontroll = false;
                this.switchStates.switchStatesPositivtRes = false;
                this.switchStates.switchStatesKassaLik = false;
                this.switchStates.switchStatesLessThan3 = false;

                this.commentsByDecisionType = {};

                if (this.clientFactoringInfo.factoringDecisionComments && this.clientFactoringInfo.factoringDecisionComments.length > 0) {
                    console.log("🚀 ~ file: ClientApproved.vue:651 ~ loadData ~ this.clientFactoringInfo.factoringDecisionComments:", this.clientFactoringInfo.factoringDecisionComments);
                    this.clientFactoringInfo.factoringDecisionComments.forEach(comment => {
                        this.handleUpdateDisplayedComment(comment);
                    });
                }
                document.title = "Kredit ";
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },
        async updateData() {
            this.$waiting.start("loading");
            try {
                console.log("UPDATING");
                this.clientFactoringInfo = await Api.createNewDecision(this.$route.params.clientId);

                //location.reload();

                this.factoringDecisionId = this.clientFactoringInfo.factoringDecisionId;
                this.isRenderedData = true;
                this.averageLast10Invoices = this.clientFactoringInfo.averageLast10Invoices;
                this.riskValueKYC = this.clientFactoringInfo.riskValueKYC;

                this.numberOfCustomersInService = this.clientFactoringInfo.numberOfCustomersInService;
                this.numberOfInvoicesLast12Months = this.clientFactoringInfo.numberOfInvoicesLast12Months;
                this.description = this.clientFactoringInfo.description;
                this.clientSince = this.clientFactoringInfo.clientSince;
                this.onAveragePaytime = this.clientFactoringInfo.onAveragePaytime;

                this.activeOpenBanking = this.clientFactoringInfo.activeOpenBanking;
                this.lessThan3UnpaidInv = this.clientFactoringInfo.lessThan3UnpaidInv;
                this.sixMonthsPositiveResult = this.clientFactoringInfo.positiveRes6Months;
                this.hasNoDebtSkattekonto = this.clientFactoringInfo.hasNoDebtSkattekonto;
                this.kassalikviditet1Point0 = this.clientFactoringInfo.kassalikviditet1Point0;
                this.hasLargerDiffThan10Percent = this.clientFactoringInfo.hasLargerDiffThan10Percent;

                this.showDebt = this.clientFactoringInfo.showDebt;
                this.showKassaLikviditet = this.clientFactoringInfo.showKassaLikviditet;
                this.showResult = this.clientFactoringInfo.showResult;
                this.showRevenue = this.clientFactoringInfo.showRevenue;
                this.showNumberOfUnpaidInvoices = this.clientFactoringInfo.showNumberOfUnpaidInvoices;
                this.totalAmountInvoicesUnpaid = this.clientFactoringInfo.totalAmountInvoicesUnpaid;
                this.hasFSkatt = this.clientFactoringInfo.hasFSkatt;
                this.creditCheck = this.clientFactoringInfo.creditCheck;
                this.showPaymentNoteCount = this.clientFactoringInfo.showPaymentNoteCount;
                this.showPaymentNoteTotalDebt = this.clientFactoringInfo.showPaymentNoteTotalDebt;
                this.showLiquidityTreshold = this.clientFactoringInfo.showLiquidityTreshold;
                this.approvedLimit = this.clientFactoringInfo.approvedLimit;
                this.signee1 = this.clientFactoringInfo.signee1;
                this.signee2 = this.clientFactoringInfo.signee2;
                this.signatoriesJson = this.clientFactoringInfo.signatoriesJson;

                this.isActive = true;

                Object.keys(this.switchStates).forEach(switchKey => {
                    this.switchStates[switchKey] = false;
                });
            } catch (error) {
                console.error("Error updating data:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },
    },

    mounted() {
        this.riskValueKYC = this.clientFactoringInfo.riskValueKYC;
        this.activeOpenBanking = this.clientFactoringInfo.activeOpenBanking;
        this.showDebt = this.clientFactoringInfo.showDebt;
    },
};
</script>
<style scoped>
@media screen and (min-width: 1383px) {
    .half-width {
        width: 50%;
    }
}
.checking {
    display: flex;
}

.value-box {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #030303;
    padding: 5px;
    z-index: 999;
}

.check {
    stroke: #fff;
    content: url(/src/assets/svg/check.svg);
    position: absolute;
    width: 20px;
    height: 20px;
    left: -2px;
    top: -2px;
    padding: 5px;
    border-radius: 3px;
}

.inactive {
    pointer-events: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.loader-icon {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both;
}

.box-card {
    width: 100%;
    margin-bottom: 20px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1000;
    top: 20%;
    left: calc(100% + 50px);
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-trigger {
    display: inline-block;
    margin-left: 10px;
}
</style>
